/* Price Chart Styles */
:root {
    --primary-color: #4a0121;
    --secondary-color: #6a0230;
    --text-color: #ffffff;
    --accent-color: #ffd700;
    --background-color: #2c0113;
    --gold-color: #ffd700;
    --silver-color: #c0c0c0;
  }
  
  .gold-price-chart-container,
  .silver-price-chart-container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding: 2rem;
    background-color: var(--background-color);
    color: var(--text-color);
    font-family: 'Arial', sans-serif;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .chart-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-align: center;
    color: var(--gold-color);
  }
  
  .silver-price-chart-container .chart-title {
    color: var(--silver-color);
  }
  
  .date-range-buttons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .date-range-button {
    background-color: var(--secondary-color);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .date-range-button:hover {
    background-color: var(--primary-color);
  }
  
  .date-range-button.active {
    background-color: var(--accent-color);
    color: var(--primary-color);
  }
  
  .silver-price-chart-container .date-range-button.active {
    background-color: var(--silver-color);
    color: var(--primary-color);
  }

  .overall-change {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  .overall-change-value {
    font-weight: bold;
    font-size: 1.3rem;
  }
  
  .overall-change-value.positive {
    color: var(--accent-color);
  }

  .silver-price-chart-container .overall-change-value.positive {
    color: var(--silver-color);
  }

  .silver-price-chart-container .overall-change-value.negative {
    color: var(--silver-color);
  }
  
  .overall-change-value.negative {
    color: var(--accent-color);
  }
  
  .chart-container {
    width: 100%;
    height: 60vh;
    min-height: 300px;
  }
  
  .no-data-message {
    text-align: center;
    padding: 2rem;
    font-size: 1.1rem;
    color: var(--silver-color);
  }
  
  /* Recharts customization */
  .recharts-cartesian-axis-tick-value {
    fill: var(--text-color);
    font-size: 10px;
  }
  
  .recharts-tooltip-wrapper {
    background-color: var(--secondary-color);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .recharts-tooltip-item {
    color: var(--accent-color);
  }
  
  .silver-price-chart-container .recharts-tooltip-item {
    color: var(--silver-color);
  }
  
  .recharts-tooltip-label {
    color: var(--text-color);
  }
  
  .recharts-active-dot {
    fill: var(--accent-color);
  }
  
  .silver-price-chart-container .recharts-active-dot {
    fill: var(--silver-color);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .gold-price-chart-container,
    .silver-price-chart-container {
      padding: 1rem 0.5rem;
      border-radius: 0;
      box-shadow: none;
    }
  
    .chart-title {
      font-size: 1.5rem;
    }
  
    .date-range-buttons {
      gap: 0.25rem;
    }
  
    .date-range-button {
      padding: 0.3rem 0.6rem;
      font-size: 0.75rem;
    }
  
    .overall-change {
      font-size: 1rem;
    }
  
    .overall-change-value {
      font-size: 1.2rem;
    }
  
    .chart-container {
      height: 50vh;
    }
  
    .recharts-wrapper {
      padding: 0 10px;
    }
  }
  
  /* Additional adjustments for very small screens */
  @media (max-width: 380px) {
    .gold-price-chart-container,
    .silver-price-chart-container {
      padding: 0.5rem 0.25rem;
    }
  
    .date-range-buttons {
      gap: 0.2rem;
    }
  
    .date-range-button {
      padding: 0.25rem 0.5rem;
      font-size: 0.7rem;
    }
  
    .chart-container {
      height: 45vh;
    }
  }